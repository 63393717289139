.alert {
  position: relative;
  padding: 1rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 50%;
  margin: 1rem auto;
}

.error {
  background-color: #f50b1e;
}

.success {
  background-color: rgb(9, 233, 9);
}

.close-btn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.hide {
  display: none;
}

.alert-icon{
display: inline-block;
margin: 0 0.5rem;
}