.about{
  background-color: rgba(0, 0, 0, 0.8);
  width: 80vw;
  margin: 2em auto;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

.about h1{
  text-transform: uppercase;
  font-weight: bold;
}

.header h2{
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 500;
}

.header{
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.green-border{
  margin-left: 0.5rem;
  width: 8em;
  height: 0.15rem;
  background-color: rgb(9, 233, 9);
}

.info-container{
  display: flex;
  width: 100%;
  height: 100%;
}

.image{
  width: 35%;
  height: 35%;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.image img{
  width: 100%;
  height: 100%;
}

.about-info{
  width: 65%;
  height: auto;
}

.about-info h3{
  text-transform: uppercase;
  color: rgb(9, 233, 9);
  letter-spacing: 2px;
  font-size: 1.2rem;
}

.about-grid-container{
  display: grid;
  grid-template-areas:  
  'birthday website'
  'age degree'
  'phone email'
  'city .';
  grid-gap: 2rem;
  margin: 1rem 0;
}

.birthday{
  grid-area: birthday;
}

.age{
  grid-area: age;
}

.website{
  grid-area: website;
}

.degree{
  grid-area: degree;
}

.phone{
  grid-area: phone;
}

.email{
  grid-area: email;
}

.city{
  grid-area: city;
}

.grid-icon{
  font-size: 1.4rem;
  color: rgb(9, 233, 9);
}

.grid-info-name{
  font-weight: 900;
}

.text-justify{
  text-align: justify;
}

.skills-grid, .interest-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.phone-number a, .email a, .website a{
  display: inline;
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .about {
    width: 100vw;
  }

  .about h1 {
    text-align: center;
  }

  .skills-grid, .interest-grid{
    grid-template-columns: 1fr 1fr 1fr;
  }

  .info-container, .image{
    flex-direction: column;
  }

  .info-container{
    margin-bottom: 2rem;
  }

  .image{
    margin: 1rem auto;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border-top: 6px solid rgb(9, 233, 9);
    border-right: 6px solid rgb(9, 233, 9); 
    border-left: 6px solid rgb(9, 233, 9);
    border-bottom: 6px solid rgb(9, 233, 9);
    /* animation: spin 10s infinite linear;
    -webkit-animation: spin 10s infinite linear; */
    margin-bottom: 1.5rem;
  }
  .image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* animation: spin 10s infinite reverse linear;
    -webkit-animation: spin 10s infinite reverse linear; */
  }

  .about-info{
    width: 100%;
  }

  .about-grid-container{
    grid-template-areas:  
    'birthday'
    'age'
    'website'
    'degree'
    'phone'
    'email'
    'city';
  }
}

@media only screen and (max-width: 750px) {
  .about{
    font-size: 14px;
  }

  .about h1 {
    transform: scale(75%);
  }
  .skills-grid, .interest-grid{
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 580px) {
  .about{
    font-size: 13px;
  }

  .about h1 {
    font-size: 1.5em;
  }

  .skills-grid, .interest-grid{
    grid-template-columns: 1fr;
  }
}

/* @keyframes spin {
  100% {
      transform: rotate(1turn);
  }
}

@-webkit-keyframes spin {
  100% {
      transform: rotate(1turn);
  }
} */

@media only screen and (max-width: 300px) {
  .about h1 {
    font-size: 1.35em;
  }

  .about-info h3{
    letter-spacing: 1px;
    font-size: 1rem;
  }
}