.home{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.photo-container{
  width: 230px;
  height: 230px;
  border-radius: 50%;
  border-top: 6px solid rgb(9, 233, 9);
   border-right: 6px solid rgb(9, 233, 9); 
  border-left: 6px solid rgb(9, 233, 9);
  border-bottom: 6px solid rgb(9, 233, 9);
  /* animation: spin 10s infinite linear;
  -webkit-animation: spin 10s infinite linear; */
  margin-bottom: 1.5rem;
}

.photo{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* animation: spin 10s infinite reverse linear;
  -webkit-animation: spin 10s infinite reverse linear; */
}

/* @keyframes spin {
  100% {
      transform: rotate(1turn);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(1turn);
  }
} */

.name{
  font-family: "Poppins", sans-serif;
  font-size: 2em;
  margin: 1rem;
}

.info{
  color:rgba(255, 255, 255, 0.7);
  font-family: "Raleway", sans-serif;
  font-size: 1.75em;
}

.info span{
  color: rgb(255, 255, 255);
  padding-bottom: 6px;
  border-bottom: 3px solid rgb(9, 233, 9);
}

.icons{
  margin-top: 2.5rem;
}

.icons a{
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  margin: 0.5rem;
}

.icons a:hover{
  cursor: pointer;
  color: rgb(9, 233, 9);
}

@media only screen and (max-height: 425px) {
  .photo-container{
    width: 175px !important;
    height: 175px !important;
    margin-bottom: 0 !important;
  }

  .info{
    font-size: 1em !important;
  }

  .home{
    padding-top: 2rem;
  }

  .name{
    font-size: 1.25em !important;
  }

  .icons{
    margin-top: 1.75rem;
  }
}

@media only screen and (max-height: 300px) {
  .photo-container{
    width: 150px !important;
    height: 150px !important;
    margin-bottom: 0 !important;
  }

  .info{
    font-size: 0.75em !important;
  }

  .home{
    padding-top: 1.75rem;
  }

  .name{
    font-size: 1.25em !important;
    margin: 0.5rem;
  }

  .icons{
    margin-top: 1.25rem;
  }
}

@media only screen and (max-height: 650px) {
  .photo-container{
    width: 185px;
    height: 185px;
  }
}

@media only screen and (max-width: 750px) {
  .photo-container{
    width: 195px;
    height: 195px;
  }

  .name{
    font-size: 1.5em;
  }
  .info{
    font-size: 1.15em;
  }

  .info span{
    font-size: inherit;
  }
}

@media only screen and (max-width: 445px) {
  .name{
    font-size: 1em;
  }
  .info{
    font-size: 1em;
  }

  .info span{
    font-size: inherit;
  }

  .icons a{
    font-size: 1rem;
  }
}

@media only screen and (max-width: 380px) {
  .name{
    font-size: 0.60em;
  }
  .info{
    font-size: 0.60em;
  }

  .info span{
    font-size: inherit;
  }

  .icons a{
    font-size: 0.75rem;
  }
}