.contact-detail{
  color: #444444;
  padding: 1.25rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  overflow-x: hidden;
}

.contact-detail h3{
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 700;
}

.contact-detail p{
  padding: 0;
  color: #fff;
  line-height: 1.5rem;
  font-size: 1rem;
}

.contact-detail span{
  font-size: 2rem;
  color: rgb(9, 233, 9);
  margin: 0.5rem;
}

.contact-info{
  font-family: "Poppins";
  margin-left: 0.8rem;
}

.social-icon{
  text-decoration: none;
  color: #fff;
  font-size: 1.6rem;
  margin: 0.3rem;
}

.social-icon:hover{
  color: rgb(9, 233, 9);
}

@media only screen and (max-width: 1000px) {
  .contact-detail h3{
    font-size: 1.15rem;
  }

  .social-icon{
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 430px) {
  .contact-detail h3{
    font-size: 1rem;
  }
  .contact-detail{
    padding: 1rem;
  }
  .contact-detail p{
    line-height: 0.75rem;
    font-size: 0.75rem;
    word-wrap: break-word;
  }

  .social-icon{
    font-size: 1rem;
  }

  .contact-detail span{
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 300px) {
  .contact-detail{
    padding: 0.75rem;
  }

  .contact-detail span{
    font-size: 1rem;
  }

  .contact-info{
    margin-left: 0.4rem;
  }

  .contact-detail p{
    line-height: 0.55rem;
    font-size: 0.55rem;
    word-wrap: break-word;
  }
}