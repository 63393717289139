*{
  margin : 0;
  padding : 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  width: 100%;
  height: 100%;
}

.app{
  background-image: url(/static/media/banner.1610a0ff.jpg);
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white;
  left: 0;
  right: 0;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}


::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  background: rgba(9, 233, 9, 0.7); 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: rgba(7, 168, 7, 0.7); 
}


.navigation{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  background-color: rgba(0, 0, 0, 0.8);
}

.navigation h1{
  font-weight: 700;
}

.menu{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
}

.menu li{
  margin: 1rem 1.5rem;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  -webkit-transform: rotate(405deg);
          transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  -webkit-transform: rotate(-405deg);
          transform: rotate(-405deg);
}

.nav-links{
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.nav-links:hover, .active-nav-links{
  color: white;
  border-bottom: 2px solid rgb(9, 233, 9);
  padding-bottom: 6px;
  text-decoration: none;
  text-decoration: none;
}

.logo{  
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: 850px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 10000;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }

  .navigation {
    height: 60px;
  }

  .logo {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 445px) {
  .about h1 {
    font-size: 1.75em;
  }
  .active-nav-links, .nav-links {
    font-size: 0.75em;
  }
}

@media only screen and (max-height: 400px) {
  .navigation{
    margin-bottom: 1rem;
  }
}

.home{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.photo-container{
  width: 230px;
  height: 230px;
  border-radius: 50%;
  border-top: 6px solid rgb(9, 233, 9);
   border-right: 6px solid rgb(9, 233, 9); 
  border-left: 6px solid rgb(9, 233, 9);
  border-bottom: 6px solid rgb(9, 233, 9);
  /* animation: spin 10s infinite linear;
  -webkit-animation: spin 10s infinite linear; */
  margin-bottom: 1.5rem;
}

.photo{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* animation: spin 10s infinite reverse linear;
  -webkit-animation: spin 10s infinite reverse linear; */
}

/* @keyframes spin {
  100% {
      transform: rotate(1turn);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(1turn);
  }
} */

.name{
  font-family: "Poppins", sans-serif;
  font-size: 2em;
  margin: 1rem;
}

.info{
  color:rgba(255, 255, 255, 0.7);
  font-family: "Raleway", sans-serif;
  font-size: 1.75em;
}

.info span{
  color: rgb(255, 255, 255);
  padding-bottom: 6px;
  border-bottom: 3px solid rgb(9, 233, 9);
}

.icons{
  margin-top: 2.5rem;
}

.icons a{
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  margin: 0.5rem;
}

.icons a:hover{
  cursor: pointer;
  color: rgb(9, 233, 9);
}

@media only screen and (max-height: 425px) {
  .photo-container{
    width: 175px !important;
    height: 175px !important;
    margin-bottom: 0 !important;
  }

  .info{
    font-size: 1em !important;
  }

  .home{
    padding-top: 2rem;
  }

  .name{
    font-size: 1.25em !important;
  }

  .icons{
    margin-top: 1.75rem;
  }
}

@media only screen and (max-height: 300px) {
  .photo-container{
    width: 150px !important;
    height: 150px !important;
    margin-bottom: 0 !important;
  }

  .info{
    font-size: 0.75em !important;
  }

  .home{
    padding-top: 1.75rem;
  }

  .name{
    font-size: 1.25em !important;
    margin: 0.5rem;
  }

  .icons{
    margin-top: 1.25rem;
  }
}

@media only screen and (max-height: 650px) {
  .photo-container{
    width: 185px;
    height: 185px;
  }
}

@media only screen and (max-width: 750px) {
  .photo-container{
    width: 195px;
    height: 195px;
  }

  .name{
    font-size: 1.5em;
  }
  .info{
    font-size: 1.15em;
  }

  .info span{
    font-size: inherit;
  }
}

@media only screen and (max-width: 445px) {
  .name{
    font-size: 1em;
  }
  .info{
    font-size: 1em;
  }

  .info span{
    font-size: inherit;
  }

  .icons a{
    font-size: 1rem;
  }
}

@media only screen and (max-width: 380px) {
  .name{
    font-size: 0.60em;
  }
  .info{
    font-size: 0.60em;
  }

  .info span{
    font-size: inherit;
  }

  .icons a{
    font-size: 0.75rem;
  }
}
.about{
  background-color: rgba(0, 0, 0, 0.8);
  width: 80vw;
  margin: 2em auto;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

.about h1{
  text-transform: uppercase;
  font-weight: bold;
}

.header h2{
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 500;
}

.header{
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.green-border{
  margin-left: 0.5rem;
  width: 8em;
  height: 0.15rem;
  background-color: rgb(9, 233, 9);
}

.info-container{
  display: flex;
  width: 100%;
  height: 100%;
}

.image{
  width: 35%;
  height: 35%;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.image img{
  width: 100%;
  height: 100%;
}

.about-info{
  width: 65%;
  height: auto;
}

.about-info h3{
  text-transform: uppercase;
  color: rgb(9, 233, 9);
  letter-spacing: 2px;
  font-size: 1.2rem;
}

.about-grid-container{
  display: grid;
  grid-template-areas:  
  'birthday website'
  'age degree'
  'phone email'
  'city .';
  grid-gap: 2rem;
  margin: 1rem 0;
}

.birthday{
  grid-area: birthday;
}

.age{
  grid-area: age;
}

.website{
  grid-area: website;
}

.degree{
  grid-area: degree;
}

.phone{
  grid-area: phone;
}

.email{
  grid-area: email;
}

.city{
  grid-area: city;
}

.grid-icon{
  font-size: 1.4rem;
  color: rgb(9, 233, 9);
}

.grid-info-name{
  font-weight: 900;
}

.text-justify{
  text-align: justify;
}

.skills-grid, .interest-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.phone-number a, .email a, .website a{
  display: inline;
  color: inherit;
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .about {
    width: 100vw;
  }

  .about h1 {
    text-align: center;
  }

  .skills-grid, .interest-grid{
    grid-template-columns: 1fr 1fr 1fr;
  }

  .info-container, .image{
    flex-direction: column;
  }

  .info-container{
    margin-bottom: 2rem;
  }

  .image{
    margin: 1rem auto;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border-top: 6px solid rgb(9, 233, 9);
    border-right: 6px solid rgb(9, 233, 9); 
    border-left: 6px solid rgb(9, 233, 9);
    border-bottom: 6px solid rgb(9, 233, 9);
    /* animation: spin 10s infinite linear;
    -webkit-animation: spin 10s infinite linear; */
    margin-bottom: 1.5rem;
  }
  .image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* animation: spin 10s infinite reverse linear;
    -webkit-animation: spin 10s infinite reverse linear; */
  }

  .about-info{
    width: 100%;
  }

  .about-grid-container{
    grid-template-areas:  
    'birthday'
    'age'
    'website'
    'degree'
    'phone'
    'email'
    'city';
  }
}

@media only screen and (max-width: 750px) {
  .about{
    font-size: 14px;
  }

  .about h1 {
    -webkit-transform: scale(75%);
            transform: scale(75%);
  }
  .skills-grid, .interest-grid{
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 580px) {
  .about{
    font-size: 13px;
  }

  .about h1 {
    font-size: 1.5em;
  }

  .skills-grid, .interest-grid{
    grid-template-columns: 1fr;
  }
}

/* @keyframes spin {
  100% {
      transform: rotate(1turn);
  }
}

@-webkit-keyframes spin {
  100% {
      transform: rotate(1turn);
  }
} */

@media only screen and (max-width: 300px) {
  .about h1 {
    font-size: 1.35em;
  }

  .about-info h3{
    letter-spacing: 1px;
    font-size: 1rem;
  }
}
.skill{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bar-container{
  width:100%;
  height: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
}

.bar{
  height: inherit;
  background-color: rgb(9, 233, 9);
}
.interest{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  font-size: 0.9rem;
}

.interest span{
  font-size: 1.5rem;
}

.interest p{
  display: flex;
  align-items: center;
}
.resume{
  background-color: rgba(0, 0, 0, 0.8);
  width: 80vw;
  margin: 2em auto;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
}

.resume h1{
  text-transform: uppercase;
  font-weight: bold;
}

.resume h3{
  margin: 1.5rem 0;
}

.header h2{
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 500;
}

.header{
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.green-border{
  margin-left: 0.5rem;
  width: 8em;
  height: 0.15rem;
  background-color: rgb(9, 233, 9);
}

.resume .item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  position: relative;
}

.resume .item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: rgb(9, 233, 9);
  border: 2px solid rgb(9, 233, 9);
}

.resume .item h4{
  line-height: 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: rgb(9, 233, 9);
  margin-bottom: 1rem;
}

.resume .item h5{
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .item p{
  margin-bottom: 0.5rem;
  text-align: justify;
}

.resume .item ul{
  padding-left: 1.2rem;
  display: block;
  list-style-type: disc;
}

.resume .item ul li{
  padding-bottom: 0.6rem;
  display: list-item;
}

.resume-items-container{
  display: flex;
}

.resume-items-container div{
  margin-right: 1rem;
}

.resume-items-container .item a{
  display: block;
  text-decoration: none;
  background: rgb(9, 233, 9);
  padding: 0.5rem;
  width: 25%;
  color: #fff;
  text-align: center;
  margin-bottom: 0.1rem;
}

@media only screen and (max-width: 1000px) {
  .resume, .resume-items-container{
    width: 100%;
  }
  
  .resume-items-container{
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .resume h1{
    font-size: 1.75em;
  }
}

@media only screen and (max-width: 575px) {
  .resume {
    font-size: 14px;
  }

  .resume h1{
    font-size: 1.65em;
  }
}

@media only screen and (max-width: 465px) {
  .resume-items-container .item a{
    width: 40%;
  }

  .resume-items-container .item h4{
    font-size: 1rem;
  }
  
}
.contact{
  background-color: rgba(0, 0, 0, 0.8);
  width: 80vw;
  margin: 1.75rem auto;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
}

.contact h1{
  text-transform: uppercase;
  font-weight: bold;
}

.header h2{
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 500;
}

.header{
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.green-border{
  margin-left: 0.5rem;
  width: 8em;
  height: 0.15rem;
  background-color: rgb(9, 233, 9);
}

.contact-details{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2rem;
  margin: 1rem 0;
}

.contact-form{
  width: 100%;
}

.contact-form form{
  padding: 1.9rem;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: column;
}

.form-grid{
  display: grid;
  grid-template-areas:  
  'name email'
  'subject subject'
  'message message';
  grid-gap: 2rem;
}

.name{
  grid-area: name;
}

.email{
  grid-area: email;
}

.subject{
  grid-area: subject;
}

.message{
  grid-area: message;
}

.contact-form form input, .contact-form form textarea{
  padding: 0.63rem 0.94rem;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.08);
  border: 0;
  transition: 0.3s;
  color: #fff;
  outline: none;
  display: inline-block;
  margin: 0;
  font-family: "Poppins";
}

.contact-form form input:focus, .contact-form form textarea:focus{
  background: rgba(255, 255, 255, 0.199);
}

.submit{
  background: rgb(9, 233, 9);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
  margin: 1rem auto;
}

.submit:hover{
  background: rgb(6, 165, 6);
  cursor: pointer;
}

.submit:disabled, .submit[disabled]{
  background: rgba(4, 102, 4, 0.5);
}

.submit:disabled:hover, .submit[disabled]:hover{
  cursor: progress;
}

@media only screen and (max-width: 1000px) {
  .contact{
    width: 100%;
    font-size: 13px;
  }
}

@media only screen and (max-width: 875px) {
  .contact h1 {
    font-size: 1.75em;
  }

  .contact-details{
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 700px) {
  .form-grid{
    grid-template-areas:  
    'name name'
    'email email'
    'subject subject'
    'message message';
    grid-gap: 1rem;
  }
}

@media only screen and (max-width: 300px) {
  .contact-form form{
    font-size: 1rem;
    padding: 0.75rem;
  }

  .contact-form form input, .contact-form form textarea{
    padding: 0.3rem 0.4rem;
    font-size: 12px;
  }
}
.contact-detail{
  color: #444444;
  padding: 1.25rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  overflow-x: hidden;
}

.contact-detail h3{
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 700;
}

.contact-detail p{
  padding: 0;
  color: #fff;
  line-height: 1.5rem;
  font-size: 1rem;
}

.contact-detail span{
  font-size: 2rem;
  color: rgb(9, 233, 9);
  margin: 0.5rem;
}

.contact-info{
  font-family: "Poppins";
  margin-left: 0.8rem;
}

.social-icon{
  text-decoration: none;
  color: #fff;
  font-size: 1.6rem;
  margin: 0.3rem;
}

.social-icon:hover{
  color: rgb(9, 233, 9);
}

@media only screen and (max-width: 1000px) {
  .contact-detail h3{
    font-size: 1.15rem;
  }

  .social-icon{
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 430px) {
  .contact-detail h3{
    font-size: 1rem;
  }
  .contact-detail{
    padding: 1rem;
  }
  .contact-detail p{
    line-height: 0.75rem;
    font-size: 0.75rem;
    word-wrap: break-word;
  }

  .social-icon{
    font-size: 1rem;
  }

  .contact-detail span{
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 300px) {
  .contact-detail{
    padding: 0.75rem;
  }

  .contact-detail span{
    font-size: 1rem;
  }

  .contact-info{
    margin-left: 0.4rem;
  }

  .contact-detail p{
    line-height: 0.55rem;
    font-size: 0.55rem;
    word-wrap: break-word;
  }
}
.alert {
  position: relative;
  padding: 1rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  width: 50%;
  margin: 1rem auto;
}

.error {
  background-color: #f50b1e;
}

.success {
  background-color: rgb(9, 233, 9);
}

.close-btn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.hide {
  display: none;
}

.alert-icon{
display: inline-block;
margin: 0 0.5rem;
}
.notFound{
  background-color: rgba(0, 0, 0, 0.8);
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
  font-family: 'Poppins';
}

.notFound h1{
  text-transform: uppercase;
  line-height: 2rem;
  margin: 1.5rem 0;
}

.notFound p{
  line-height: 2rem;
}

.notFound-link{
  color: white;
  cursor: pointer;
}

.notFound-link:hover{
  color: rgb(9, 233, 9);
}

@media only screen and (max-width: 400px){
  .notFound h1{
    font-size: 24px;
  }
}
