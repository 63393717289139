.skill{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bar-container{
  width:100%;
  height: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
}

.bar{
  height: inherit;
  background-color: rgb(9, 233, 9);
}