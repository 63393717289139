.navigation{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  background-color: rgba(0, 0, 0, 0.8);
}

.navigation h1{
  font-weight: 700;
}

.menu{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
}

.menu li{
  margin: 1rem 1.5rem;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

.nav-links{
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.nav-links:hover, .active-nav-links{
  color: white;
  border-bottom: 2px solid rgb(9, 233, 9);
  padding-bottom: 6px;
  text-decoration: none;
  text-decoration: none;
}

.logo{  
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: 850px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 10000;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }

  .navigation {
    height: 60px;
  }

  .logo {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 445px) {
  .about h1 {
    font-size: 1.75em;
  }
  .active-nav-links, .nav-links {
    font-size: 0.75em;
  }
}

@media only screen and (max-height: 400px) {
  .navigation{
    margin-bottom: 1rem;
  }
}
