.app{
  background-image: url('./images/banner.jpg');
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white;
  left: 0;
  right: 0;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}


::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  background: rgba(9, 233, 9, 0.7); 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: rgba(7, 168, 7, 0.7); 
}

