.interest{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  font-size: 0.9rem;
}

.interest span{
  font-size: 1.5rem;
}

.interest p{
  display: flex;
  align-items: center;
}