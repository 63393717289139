.resume{
  background-color: rgba(0, 0, 0, 0.8);
  width: 80vw;
  margin: 2em auto;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
}

.resume h1{
  text-transform: uppercase;
  font-weight: bold;
}

.resume h3{
  margin: 1.5rem 0;
}

.header h2{
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-size: 1rem;
  font-weight: 500;
}

.header{
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.green-border{
  margin-left: 0.5rem;
  width: 8em;
  height: 0.15rem;
  background-color: rgb(9, 233, 9);
}

.resume .item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  position: relative;
}

.resume .item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: rgb(9, 233, 9);
  border: 2px solid rgb(9, 233, 9);
}

.resume .item h4{
  line-height: 1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: rgb(9, 233, 9);
  margin-bottom: 1rem;
}

.resume .item h5{
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .item p{
  margin-bottom: 0.5rem;
  text-align: justify;
}

.resume .item ul{
  padding-left: 1.2rem;
  display: block;
  list-style-type: disc;
}

.resume .item ul li{
  padding-bottom: 0.6rem;
  display: list-item;
}

.resume-items-container{
  display: flex;
}

.resume-items-container div{
  margin-right: 1rem;
}

.resume-items-container .item a{
  display: block;
  text-decoration: none;
  background: rgb(9, 233, 9);
  padding: 0.5rem;
  width: 25%;
  color: #fff;
  text-align: center;
  margin-bottom: 0.1rem;
}

@media only screen and (max-width: 1000px) {
  .resume, .resume-items-container{
    width: 100%;
  }
  
  .resume-items-container{
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .resume h1{
    font-size: 1.75em;
  }
}

@media only screen and (max-width: 575px) {
  .resume {
    font-size: 14px;
  }

  .resume h1{
    font-size: 1.65em;
  }
}

@media only screen and (max-width: 465px) {
  .resume-items-container .item a{
    width: 40%;
  }

  .resume-items-container .item h4{
    font-size: 1rem;
  }
  
}